// Implement criteria for displaying warnings derived from forecast data.
// Initially works with routing results, might be more generally useful later.
// See also weatherWarnings.py
import * as pwMap from './map.js';
import {
    close_text,
    PWRoutingTableWarning,
    WARNING,
    WARNING_TEXT,
    warning_title,
    warningOrange
} from './components/routing-table-warning.js';
import warningIconImage from './images/routing-warning-icon.svg';
import close_icon from './images/warning-close-icon.svg';

export {warningIconImage};

customElements.define('pw-routing-table-warning', PWRoutingTableWarning);

const fog_minimum = 0.25, rime_minimum = 0.75;
// Look for convertKnotsToMetresPerSecond() for converting our nominal speed limits in knots into the router's output units
// (confusingly, almost all speeds out of the router are in m/s but the bsp is in km/h! We don't use that here.)
const gust_sufficent = 40 * 0.5144; // sufficient (not necessary) gust value to trigger a warning, in m/s
const gust_minimum = 25 * 0.5144; // minimum gust value to trigger a warning, in m/s
const squall_gust_minimum = 25 * 0.5144; // minimum gust value to trigger a squall warning, in m/s
const squall_rain_minimum = 3; // minimum rain to trigger squall warning in mm/hr
const wac_wind_speed_minimum = 12 * 0.5144; // minimum wind speed to trigger Wind against Current, in m/s
const wac_current_speed_minimum = 1.0 * 0.5144; // minimum current speed to trigger Wind against Current, in m/s
const wind_chill_temperature_maximum = 8;  // Threshold for triggering warning on result of wind chill calculation, ℃
// Agreed string (Symbol?) for annotating objects handed to us (without risk of collisions?)
// If this is a string we might as well just use it directly I guess?
export const WARNINGS = 'WARNINGS'; // Symbol('warnings');

const gettext = window.gettext;
const interpolation_text_departure_warning = gettext("warning: departure %s");

function addWarningsSetToRouteNode(node, ROUTING_INVALID_VALUE, warnings_to_suppress) {
    // Sadly all node values should be checked against ROUTING_INVALID_VALUE; see function valid() below.
    // Units determined by reading routing.js
    // node.tws: wind speed in km/h
    // node.gust: gust speed in km/h
    // node.cape: CAPE in J/kg
    // node.rain: rain in mm/hr
    // node.temp: temperature in °C
    // node.roll: roll in degrees
    // node.vertacc: vertical acceleration in g
    // node.slaminc: slamming inc as a percentage (what is Inc???)
    warnings_to_suppress = warnings_to_suppress ?? new Set;
    const {tws, fog, gust, cape, rain, temp, roll, vertacc, slaminc, cd, cs, gws, gwd, lightning} = node;

    // Fog and Rime
    if (valid(fog)) {
        if (fog >= rime_minimum) {
            addWarning(WARNING.FOG_AND_RIME);
        } else if (fog >= fog_minimum) {
            addWarning(WARNING.FOG);
        }
    }

    // Gust
    if (valid(gust)) {
        if (gust >= gust_sufficent) {
            addWarning(WARNING.GUST);
        } else if (valid(tws) && (gust >= gust_minimum && gust >= 1.5 * tws)) {
            addWarning(WARNING.GUST);
        }
    }

    // Squall
    if (valid(gust, rain) && rain > squall_rain_minimum && gust > squall_gust_minimum) {
        addWarning(WARNING.SQUALL);
    }

    // Note that only Wind Chill needs suppression implemented for the momemnt.
    if (!warnings_to_suppress.has(WARNING.CHILL)) {
        // Wind Chill Index = 13.12 + 0.6215(Air Temp in Celsius) - 11.37(10m wind speed in km/h)^0.16
        //                    + 0.3965(Air Temp in Celsius)(10m wind speed in km/h)^0.16
        // Issue a warning if that is less than zero
        if (valid(temp, tws)) {
            const wci = 13.12 + (0.6215 * temp) - 11.37 * Math.pow(tws, 0.16) + (0.3965 * temp * Math.pow(tws, 0.16));
            if (wci <= wind_chill_temperature_maximum) {
                addWarning(WARNING.CHILL);
            }
        }
    }

    // Thunderstorm
    if (valid(temp, cape)) {
        if (temp < 20 && cape > 1000 || temp < 30 && cape > 2000 || cape > 3000) {
            addWarning(WARNING.THUNDERSTORM);
        }
    }

    // Lightning - below 0.1 is not significant
    if (valid(lightning) && lightning >= 0.1) {
        addWarning(WARNING.LIGHTNING);
    }

    // Note that the following inequalities match some histogramming that we already do in the routing tables,
    // see tripStatistics() in routing.js

    // Roll
    if (valid(roll) && roll >= 4) {
        addWarning(WARNING.ROLL);
    }

    // Vertical Acceleration
    if (valid(vertacc) && Math.abs(vertacc) >= 0.2) {
        addWarning(WARNING.VACC);
    }

    // Slamming Inc
    if (valid(slaminc) && slaminc >= 50) {
        addWarning(WARNING.SLAM);
    }

    // Wind against Current
    // up to 60 degrees difference between current direction and gwd (which are measured in opposite senses!)
    // but only when wind speed and current speed meet certain thresholds, see constants.
    if (valid(cd, cs, gws, gwd) && cs >= wac_current_speed_minimum && gws >= wac_wind_speed_minimum) {
        const d = (gwd - cd + 3600) % 360;
        if (d >= 300 || d <= 60) {
            addWarning(WARNING.WAC);
        }
    }

    // We are done!
    return node[WARNINGS];

    function valid(...values) {
        return values.every(value => value !== null && !isNaN(value) && value !== ROUTING_INVALID_VALUE);
    }

    function addWarning(warning) {
        (node[WARNINGS] = node[WARNINGS] ?? new Set).add(warning);
    }

}

const
    icon = pwMap.icon({
        img: warningIconImage,
        width: 28,
        height: 28,
        anchorY: 14
    }),
    close_image_styles = {
        display: 'inline-block',
        width: '22px',
        height: '22px',
        cursor: 'pointer',
        marginLeft: '17px'
    },
    popup = pwMap.element({
        lat: 0,
        lon: 0,
        hidden: true,
        centred: false,
        elem: $j('<div>')
            .addClass("routing-map-warning-popup")
            .append(
                $j('<img>', {alt: close_text, src: close_icon}).css({...close_image_styles, float: "right"}),
                $j('<span>').text(warning_title).css({textTransform: "uppercase", fontWeight: "400"}),
                $j('<div>').addClass('routing-map-warning-popup-list')
            )[0]
    });
popup.pointerEvents = false; // Required because of translation of the child, otherwise there's an invisible clickable rect.

$j('pw-map-forecast-options').on('source-selected', () => $j(popup.element).trigger('click'));

// Due to the .css files in the Offshore App being messy it's a good idea not to involve them in styling new features!
// We need a media query to get the popup styling right, so we can't just use the style property on elements.
// We should probably use a component for the popup, but maybe that's overkill, and for the moment this works.
$j('<style>').html( // language=css
    `
        .routing-map-warning-popup {
            color: White;
            background-color: ${warningOrange};
            line-height: 17px;
            font-weight: 700;
            font-size: 14px;
            padding: 8px;
            min-width: 200px;
            border-radius: 8px;
            pointer-events: auto;
            transform: translate(-50%, calc(-100% - 18px));
        }

        @media only screen
        and (min-width: 320px)
        and (max-width: 736px) /* See comment in routing-waves-tables.js GHB */
        and (orientation: portrait) {
            .routing-map-warning-popup {
                transform: none;
                position: fixed;
                left: 7px;
                right: 7px;
                bottom: 106px;
                z-index: 10;
            }
        }
    `).appendTo(document.head);

export function addWarningsToRoute(route, ROUTING_INVALID_VALUE) {
    // route is an array; we can add a property to it to avoid adding all the warning information twice.
    if (route[WARNINGS]) {
        return route[WARNINGS];
    }
    let allWarnings = route[WARNINGS] = new Set;
    // A 'route' might be a routing result, or it might be a historical track. Don't show any warnings
    // if this route appears to be the latter. i.e. even the last node is in the past.
    if (!route.length || route[route.length-1].t < Date.now()/1000) {
        return allWarnings;
    }
    console.log(`Annotating route ${
        new Date(1000*route[0].t).toUTCString()}–${
        new Date(1000*route[route.length-1].t).toUTCString()
    }`);
    // Some challenging logic that makes this whole function substantially more complicated:
    // If we get a 24hr run of Wind Chill warnings, then we should stop emitting wind chill warnings
    // after that.
    const warnings_to_suppress = new Set;
    let chill_run_began_timestamp = null;

    for (const node of route) {
        const ws = addWarningsSetToRouteNode(node, ROUTING_INVALID_VALUE, warnings_to_suppress);
        ws?.forEach(w => allWarnings.add(w));
        if (ws && ws.has(WARNING.CHILL)) {
            if (chill_run_began_timestamp === null) {
                // No run was underway, so a new run starts now.
                chill_run_began_timestamp = node.t;
            } else {
                // A run was already underway, has it exceeded 24 hours in length yet?
                if (node.t - chill_run_began_timestamp > 24 * 3600) {
                    // Disable chill warnings going forward
                    console.log("Suppressing chill warnings after a 24 hour run of them", node.t);
                    warnings_to_suppress.add(WARNING.CHILL);
                } // Otherwise, run continues, take no action.
            }
        } else {
            // No chill warning; so any run that was underway is over now.
            // (Possibly due to suppression; that's fine.)
            chill_run_began_timestamp = null;
        }
    }
    return allWarnings;
}


export function warningItemsFromRoute(route, group, ROUTING_INVALID_VALUE, index) {
    const
        backgroundFormatting = {
            lineColour: '#FFF',
            lineWeight: 10,
            lineOpacity: 0.5,
            zIndex: pwMap.zLevels.routePath
        },
        foregroundFormatting = {
            lineColour: warningOrange,
            lineWeight: 4,
            lineOpacity: 1,
            zIndex: pwMap.zLevels.routePath
        };

    addWarningsToRoute(route, ROUTING_INVALID_VALUE);
    const
        addItem = it => group ? pwMap.LayerGroup.addItem(group, it) : PWGMap.addItem(it),
        removeItem = it => group ? pwMap.LayerGroup.removeItem(group, it) : PWGMap.removeItem(it),
        items = [popup];
    let path = null,
        pathWarnings = null;
    for (const node of route.concat({})) { // Include a final fake no-warnings node as a sentinel to wrap up the algorithm!
        if (node[WARNINGS]) {
            if (!path) {
                path = [];
                pathWarnings = new Set;
            }
            path.push(node.p);
            node[WARNINGS].forEach(warning => pathWarnings.add(warning));
        } else {
            // Wrap up polylineCoords gathered so far, but drop if there are fewer than 2 points
            if (path && path.length >= 2) {
                const
                    polylines = [
                        pwMap.polyline({path, ...backgroundFormatting}),
                        pwMap.polyline({path, ...foregroundFormatting})
                    ],
                    marker = pwMap.marker(Object.assign({
                        icon,
                        click(coords) {
                            console.log("Warning marker click handler", coords, this[WARNINGS]);
                            popup.polylines?.forEach(removeItem);
                            popup.polylines = polylines;
                            polylines.forEach(addItem);
                            pwMap.element(popup, {
                                hidden: false,
                                lat: this.lat,
                                lon: this.lon,
                            });
                            $j(popup.element)
                                .off('click')
                                .on('click', event => {
                                    console.warn("Popup element click handler", event);
                                    event.stopPropagation();
                                    popup.polylines?.forEach(removeItem);
                                    pwMap.element(popup, {hidden: true});
                                })
                                .find('.routing-map-warning-popup-list')
                                .empty()
                                .append([...this[WARNINGS]].map(w => $j('<div>')
                                    .text(WARNING_TEXT[w])
                                    .css('white-space', 'nowrap')
                                ));
                            if (PWGMap.getRoutingMode() === "TripPlanning") {
                                let warning_text_dept = window.interpolate(interpolation_text_departure_warning, [PWGMap.displayedRouteInfo.routeList[index].params.departureIndex + 1]);
                                $j(popup.element)
                                    .children('span')
                                    .text(warning_text_dept);
                            }
                        }
                    }, path[0]));
                marker[WARNINGS] = pathWarnings;
                console.log(`Made a warning polyline with ${path.length} points`, polylines[1]);
                items.push(marker);
            }
            path = pathWarnings = null;
        }
    }
    items.forEach(addItem);
    return items;
}
