import {
    add_conversion, add_object_conversion, convert, make_field, formatted_fields, define_format, define_unit, define_formats, define_objects
} from './convertable.js';
import {html} from "lit";

import {units,formats} from './convertable-units.js';
import {preference_units} from './preference-units.js';

export const html_route_warnings = define_format({key:'html_route_warnings'});
export const route_warnings = define_unit({ key:'route_warnings', format:html_route_warnings });

add_conversion(route_warnings, html_route_warnings, (value) => html`
    <pw-routing-table-warning .warnings=${value}>
    </pw-routing-table-warning>`);


export const routing_units = {
    node_orig: {
        make_context: (node, context) => ({
            utcOffset:context.utcOffset,
            mag_decl:node.bearing !== null && node.maghdg !== null ? node.bearing - node.maghdg : null,
            lat:node.p?.lat,
            lon:node.p?.lon,
        }),
        fields:{
            t:make_field({unit:units.timestamp}),
            // route
            p:{ lat:make_field({unit:units.latitude}), lon:make_field({unit:units.longitude}) },
            bearing:make_field({unit:units.degrees_true}),
            maghdg:make_field({unit:units.degrees_magnetic}),
            bsp:make_field({unit:units.kilometres_per_hour}),
            sog:make_field({unit:units.knots}),
            cog:make_field({unit:units.degrees_true}),
            motoring:make_field({unit:units.boolean}),
            accumfuelconsumed:make_field({unit:units.litres}),
            depth:make_field({unit:formats.string_depth}),
            // wind
            tws:make_field({unit:units.metres_per_second}),
            gust:make_field({unit:units.metres_per_second}),
            twd:make_field({unit:units.degrees_true}),
            twa:make_field({unit:units.twa}),
            // wave
            wh:make_field({unit:units.metres}),
            wd:make_field({unit:units.degrees_true}),
            wp:make_field({unit:units.seconds}),
            shww:make_field({unit:units.metres}),
            mdww:make_field({unit:units.degrees_true}),
            mpww:make_field({unit:units.seconds}),
            swh1:make_field({unit:units.metres}),
            mwd1:make_field({unit:units.degrees_true}),
            mwp1:make_field({unit:units.seconds}),
            swh2:make_field({unit:units.metres}),
            mwd2:make_field({unit:units.degrees_true}),
            mwp2:make_field({unit:units.seconds}),
            swh3:make_field({unit:units.metres}),
            mwd3:make_field({unit:units.degrees_true}),
            mwp3:make_field({unit:units.seconds}),
            roll:make_field({unit:units.degrees_angle}),
            vertacc:make_field({unit:units.g_force}),
            slaminc:make_field({unit:units.slams_per_hour}),
            // current
            cs:make_field({unit:units.metres_per_second}),
            cd:make_field({unit:units.degrees_true}),
            // atmosphere
            press:make_field({unit:units.kilopascals}),
            temp:make_field({unit:units.celsius}),
            rain:make_field({unit:units.millimetres_per_hour}),
            cape:make_field({unit:units.joules_per_kilogram}),
            fog:make_field({unit:units.fog_rime_factor}),
            lightning:make_field({unit:units.lightning_strikes_per_100_square_kilometres}),
            // other
            WARNINGS:make_field({unit:route_warnings})
        }
    },

    node_user: {
        make_context:null, // comes from node_orig
        fields:{
            time:make_field({unit:units.timestamp}),
            time_short:make_field({unit:units.timestamp_short}),
            // route
            lat:make_field({unit:units.latitude}),
            lon:make_field({unit:units.longitude}),
            p:{ lat:make_field({unit:units.latitude}), lon:make_field({unit:units.longitude}) },
            ip:{ lat:make_field({unit:units.latitude}), lon:make_field({unit:units.longitude}) },
            heading:make_field({unit:preference_units.boat_direction}),
            bsp:make_field({unit:preference_units.boat_speed}),
            sog:make_field({unit:preference_units.boat_speed}),
            cog:make_field({unit:preference_units.boat_direction}),
            motoring:make_field({unit:units.boolean}),
            accumfuelconsumed:make_field({unit:preference_units.fuel_volume}),
            depth:make_field({unit:formats.string_depth}),
            // wind
            tws:make_field({unit:preference_units.wind_speed}),
            gust:make_field({unit:preference_units.wind_speed}),
            twd:make_field({unit:preference_units.wind_direction}),
            twa:make_field({unit:units.twa}),
            // wave
            wh:make_field({unit:preference_units.wave_height}),
            wd:make_field({unit:preference_units.wave_direction}),
            wp:make_field({unit:preference_units.wave_period}),
            shww:make_field({unit:preference_units.wave_height}),
            mdww:make_field({unit:preference_units.wave_direction}),
            mpww:make_field({unit:preference_units.wave_period}),
            swh1:make_field({unit:preference_units.wave_height}),
            mwd1:make_field({unit:preference_units.wave_direction}),
            mwp1:make_field({unit:preference_units.wave_period}),
            swh2:make_field({unit:preference_units.wave_height}),
            mwd2:make_field({unit:preference_units.wave_direction}),
            mwp2:make_field({unit:preference_units.wave_period}),
            swh3:make_field({unit:preference_units.wave_height}),
            mwd3:make_field({unit:preference_units.wave_direction}),
            mwp3:make_field({unit:preference_units.wave_period}),
            roll:make_field({unit:preference_units.roll}),
            vertacc:make_field({unit:preference_units.vertical_acceleration}),
            slaminc:make_field({unit:preference_units.slamming_incidence}),
            // current
            cs:make_field({unit:preference_units.boat_speed}),
            cd:make_field({unit:preference_units.wave_direction}),
            // atmosphere
            press:make_field({unit:preference_units.pressure}),
            temp:make_field({unit:preference_units.temperature}),
            rain:make_field({unit:preference_units.rainfall}),
            cape:make_field({unit:preference_units.cape}),
            fog:make_field({unit:preference_units.fog}),
            lightning:make_field({unit:preference_units.lightning}),
            // other
            WARNINGS:make_field({unit:route_warnings})
        }
    },

    // TODO: need a better was to switch out units, and control whether or not to
    node_user_non_magnetic: {
        make_context:null, // comes from node_orig
        fields:{
            time:make_field({unit:units.timestamp}),
            time_short:make_field({unit:units.timestamp_short}),
            // route
            lat:make_field({unit:units.latitude}),
            lon:make_field({unit:units.longitude}),
            p:{ lat:make_field({unit:units.latitude}), lon:make_field({unit:units.longitude}) },
            ip:{ lat:make_field({unit:units.latitude}), lon:make_field({unit:units.longitude}) },
            heading:make_field({unit:units.degrees_true}),
            bsp:make_field({unit:preference_units.boat_speed}),
            sog:make_field({unit:preference_units.boat_speed}),
            cog:make_field({unit:units.degrees_true}),
            motoring:make_field({unit:units.boolean}),
            accumfuelconsumed:make_field({unit:preference_units.fuel_volume}),
            depth:make_field({unit:formats.string_depth}),
            // wind
            tws:make_field({unit:preference_units.wind_speed}),
            gust:make_field({unit:preference_units.wind_speed}),
            twd:make_field({unit:units.degrees_true}),
            twa:make_field({unit:units.twa}),
            // wave
            wh:make_field({unit:preference_units.wave_height}),
            wd:make_field({unit:units.degrees_true}),
            wp:make_field({unit:preference_units.wave_period}),
            shww:make_field({unit:preference_units.wave_height}),
            mdww:make_field({unit:units.degrees_true}),
            mpww:make_field({unit:preference_units.wave_period}),
            swh1:make_field({unit:preference_units.wave_height}),
            mwd1:make_field({unit:units.degrees_true}),
            mwp1:make_field({unit:preference_units.wave_period}),
            swh2:make_field({unit:preference_units.wave_height}),
            mwd2:make_field({unit:units.degrees_true}),
            mwp2:make_field({unit:preference_units.wave_period}),
            swh3:make_field({unit:preference_units.wave_height}),
            mwd3:make_field({unit:units.degrees_true}),
            mwp3:make_field({unit:preference_units.wave_period}),
            roll:make_field({unit:preference_units.roll}),
            vertacc:make_field({unit:preference_units.vertical_acceleration}),
            slaminc:make_field({unit:preference_units.slamming_incidence}),
            // current
            cs:make_field({unit:preference_units.boat_speed}),
            cd:make_field({unit:units.degrees_true}),
            // atmosphere
            press:make_field({unit:preference_units.pressure}),
            temp:make_field({unit:preference_units.temperature}),
            rain:make_field({unit:preference_units.rainfall}),
            cape:make_field({unit:preference_units.cape}),
            fog:make_field({unit:preference_units.fog}),
            lightning:make_field({unit:preference_units.lightning}),
            // other
            WARNINGS:make_field({unit:route_warnings})
        }
    },

    summary_orig: {
        make_context:(_summary, context) => ({ utcOffset:context.utcOffset }),
        fields:{
            startTime:make_field({unit:units.timestamp}),
            endTime:make_field({unit:units.timestamp}),
            timeTaken:make_field({unit:units.timespan}),
            distanceTravelled:make_field({unit:units.nautical_miles}),
            fuelConsumed:make_field({unit:units.litres}),
            averageSpeed:make_field({unit:units.knots})
        }
    },

    summary_user: {
        make_context:null, // comes from summary_orig
        fields:{
            startTime:make_field({unit:units.timestamp}),
            endTime:make_field({unit:units.timestamp}),
            timeTaken:make_field({unit:units.timespan}),
            distanceTravelled:make_field({unit:preference_units.distance}),
            fuelConsumed:make_field({unit:preference_units.fuel_volume}),
            averageSpeed:make_field({unit:preference_units.boat_speed})
        }
    },

};
define_objects('routing_units', routing_units);

export const routing_formats = {
    node: {
        fields:
            formatted_fields(
                routing_units.node_user.fields,
                {
                    motoring:make_field({unit:formats.string_motoring})
                })
    },

    table_node: {
        fields:
            formatted_fields(
                routing_units.node_user.fields,
                {
                    motoring:make_field({unit:formats.string_motoring}),
                    twa:make_field({unit:formats.string_table_twa})
                })
    },

    summary: { fields:{
        startTime:make_field({unit:formats.string_time_short}),
        endTime:make_field({unit:formats.string_time_short}),
        timeTaken:make_field({unit:formats.string_time_duration}),
        distanceTravelled:make_field({unit:formats.string_1dp_unit}),
        fuelConsumed:make_field({unit:formats.string_0dp}),
        averageSpeed:make_field({unit:formats.string_1dp})
    }}
};
define_formats('routing_formats', routing_formats);

// object conversions
add_object_conversion(routing_units.node_orig, routing_units.node_user, {
    remap(node) {
        const value = {
            ...node,
            time:node.t,
            time_short:node.t,
            lat:node.p?.lat,
            lon:node.p?.lon,
            heading:preference_units.boat_direction === units.degrees_magnetic ? node.maghdg : node.bearing
        };
        delete value.t;
        delete value.bearing;
        return value;
    },
    update_context:null,
});

// TODO: need a better was to switch out units, and control whether or not to
add_object_conversion(routing_units.node_orig, routing_units.node_user_non_magnetic, {
    remap(node) {
        const value = {
            ...node,
            time:node.t,
            time_short:node.t,
            lat:node.p?.lat,
            lon:node.p?.lon,
            heading:node.bearing
        };
        delete value.t;
        delete value.bearing;
        return value;
    },
    update_context:null,
});

add_object_conversion(routing_units.node_user, routing_formats.node);
add_object_conversion(routing_units.node_user, routing_formats.table_node);
add_object_conversion(routing_units.node_user_non_magnetic, routing_formats.node);

add_object_conversion(routing_units.summary_orig, routing_units.summary_user);
add_object_conversion(routing_units.summary_user, routing_formats.summary);

// utils

export function getNodeContext(node) {
    return routing_units.node_orig.make_context(node, { utcOffset:PWGMap.utcOffset });
}

export function getSummaryContext(summary) {
    return routing_units.summary_orig.make_context(summary, { utcOffset:PWGMap.utcOffset });
}

export function getFormattedTableNode(node) {
    return convert(node, routing_units.node_orig, routing_formats.table_node, getNodeContext(node));
}

export function getFormattedSummary(summary) {
    return convert(summary, routing_units.summary_orig, routing_formats.summary, getSummaryContext(summary));
}

function conversionHelper(value, from, to, format, context) {
    const converted = convert(value, from, to, context);
    const formatted = convert(converted, to, format, context);
    return { context, converted, formatted, units:to };
}

export function getUserNode(node, context) {
    const units = (node.maghdg ?? null) !== null
        ? routing_units.node_user
        : routing_units.node_user_non_magnetic;
    return conversionHelper(node, routing_units.node_orig, units, routing_formats.node, { ...getNodeContext(node), ...context });
}
