import {css, html, LitElement} from "lit";
import {isSmartPhone} from "../../platform.js";

const txtWaveCombined = gettext('Combined Wind Wave & Swell');
const txtWindWave = gettext('Wind Wave');
const txtSwell1 = gettext('Primary Swell');
const txtSwell2 = gettext('Secondary Swell');
const txtSwell3 = gettext('Tertiary Swell');

export default class PWRoutingWavesSelector extends LitElement {
    static get properties() {
        return {
            value: {type: String, reflect: true}
        };
    }

    constructor() {
        super();
        this.value = 'combined-wave';
    }

    wavesSelectionChanged(elem) {
        let e = new CustomEvent('routing-waves-selector-changed', {
            detail: elem.target.value,
            bubbles: true,
            composed: true
        });
        this.dispatchEvent(e);
    }

    render() {
        return html`
            <select class="routing-waves-selector" .value="${this.value}" @change="${this.wavesSelectionChanged}">
                <option value='combined-wave'>${txtWaveCombined}</option>
                <option value='wind-wave'>${txtWindWave}</option>
                <option value='swell-1'>${txtSwell1}</option>
                <option value='swell-2'>${txtSwell2}</option>
                <option value='swell-3'>${txtSwell3}</option>
            </select>`;
    }

    static get styles() {
        // language=css
        const s = [css`
            .routing-waves-selector {
                border-radius: 8px;
                border: 0;
                height: 42px;
                font-family: inherit;
                font-weight: 500;
                font-size: 14px;
                color: #000;
                -webkit-appearance: none;
                background: #F8F9F9 url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDExIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNC41ODY2IDUuOTI1NThDNS4wOTA5IDYuNDk2ODYgNS45MTExNCA2LjQ5MTk2IDYuNDExMDUgNS45MjU1OEwxMC43MjI2IDEuMDMzNTRDMTEuMjI1NyAwLjQ2MjI1MyAxMS4wMjU4IDAgMTAuMjc0MyAwSDAuNzIzMzQ4Qy0wLjAyNjUwNzMgMCAtMC4yMjM2NjkgMC40NjcxNTMgMC4yNzUwMzQgMS4wMzM1NEw0LjU4NjYgNS45MjU1OFoiIGZpbGw9ImJsYWNrIi8+Cjwvc3ZnPgo=) no-repeat right 20px center;
                padding-left: 17px;
                padding-right: 50px;
            }
        `];
        return s;
    }
}
