import {css, html, LitElement, nothing} from "lit";
import {classMap} from "lit/directives/class-map.js";
import {map} from 'lit/directives/map.js';
import {styleMap} from "lit/directives/style-map.js";
import {getUserNode} from '../routing-units.js';


const txtTimeShortHeading = gettext('Time');
const txtHeightShortHeading = gettext('Height');
const txtDirectionShortHeading = gettext('Dir');
const txtPeriodShortHeading = gettext('Period');
const txtRollShortHeading = gettext('Roll');
const txtVerticalAccelerationShortHeading = gettext('Vert Acc');
const txtSlammingIncidenceShortHeading = gettext('Slam Inc');

const txtTimeLongHeading = gettext('Time');
const txtCombinedLongHeading = gettext('Combined Wind Wave & Swell');
const txtWindWaveLongHeading = gettext('Wind Wave');
const txtPrimarySwellLongHeading = gettext('Primary Swell');
const txtSecondarySwellLongHeading = gettext('Secondary Swell');
const txtTertiarySwellLongHeading = gettext('Tertiary Swell');
const txtRollLongHeading = gettext('Roll');
const txtVerticalAccelerationLongHeading = gettext('Vertical Acceleration');
const txtSlammingIncidenceLongHeading = gettext('Slamming Incidence');

const txtUpgradeMessage = gettext('Wind Wave, Primary, Secondary and Tertiary Swell, Roll, Vertical Acceleration and Slamming Incidence are only available with a Professional subscription');

export default class PWRoutingWavesTables extends LitElement {
    static get properties() {
        return {
            utcOffset: {type: Number, reflect: true},
            routeList: {type: Array, reflect: false},
            showWaveEffects: {type: Boolean},
            waveFilter: {type: String, reflect: true}
        };
    }

    constructor() {
        super();
        this.utcOffset = 0;
        this.routeList = [];
        this.showWaveEffects = false;
        this.waveFilter = 'combined-wave';
    }

    get tables() {
        const showWaveEffects = this.showWaveEffects && this.canShowWaveEffects && this.wavePolarEnabled,
            narrowHeadings = [
                txtTimeShortHeading, txtHeightShortHeading, txtDirectionShortHeading, txtPeriodShortHeading
            ].concat(
                showWaveEffects ? [txtRollShortHeading, txtVerticalAccelerationShortHeading, txtSlammingIncidenceShortHeading] : [],
                '' // empty heading for warnings
            );
        return [
            {
                className: 'wide-waves-table',
                headings: [
                    txtTimeLongHeading, txtCombinedLongHeading, txtWindWaveLongHeading,
                    txtPrimarySwellLongHeading, txtSecondarySwellLongHeading, txtTertiarySwellLongHeading
                ].concat(
                    showWaveEffects ? [txtRollLongHeading, txtVerticalAccelerationLongHeading, txtSlammingIncidenceLongHeading] : [],
                    '' // empty heading for warnings
                ),
                rowFields: [
                    [null, 'wh', 'shww', 'swh1', 'swh2', 'swh3', null].concat(showWaveEffects ? [null, null, null] : []),
                    ['time_short', 'wd', 'mdww', 'mwd1', 'mwd2', 'mwd3'].concat(showWaveEffects ? ['roll', 'vertacc', 'slaminc'] : [], 'WARNINGS'),
                    [null, 'wp', 'mpww', 'mwp1', 'mwp2', 'mwp3', null].concat(showWaveEffects ? [null, null, null] : [])
                ],
                isNarrow: false,
                isCombined: false
            },
            {
                filter: 'combined-wave',
                className: 'narrow-waves-table',
                headings: narrowHeadings,
                rowFields: [['time_short', 'wh', 'wd', 'wp'].concat(showWaveEffects ? ['roll', 'vertacc', 'slaminc'] : [], 'WARNINGS')],
                isNarrow: true,
                isCombined: true
            },
            {
                filter: 'wind-wave',
                className: 'narrow-waves-table',
                headings: narrowHeadings,
                rowFields: [['time_short', 'shww', 'mdww', 'mpww'].concat(showWaveEffects ? ['roll', 'vertacc', 'slaminc'] : [], 'WARNINGS')],
                isNarrow: true,
                isCombined: false
            },
            {
                filter: 'swell-1',
                className: 'narrow-waves-table',
                headings: narrowHeadings,
                rowFields: [['time_short', 'swh1', 'mwd1', 'mwp1'].concat(showWaveEffects ? ['roll', 'vertacc', 'slaminc'] : [], 'WARNINGS')],
                isNarrow: true
            },
            {
                filter: 'swell-2',
                className: 'narrow-waves-table',
                headings: narrowHeadings,
                rowFields: [['time_short', 'swh2', 'mwd2', 'mwp2'].concat(showWaveEffects ? ['roll', 'vertacc', 'slaminc'] : [], 'WARNINGS')],
                isNarrow: true,
                isCombined: false
            },
            {
                filter: 'swell-3',
                className: 'narrow-waves-table',
                headings: narrowHeadings,
                rowFields: [['time_short', 'swh3', 'mwd3', 'mwp3'].concat(showWaveEffects ? ['roll', 'vertacc', 'slaminc'] : [], 'WARNINGS')],
                isNarrow: true,
                isCombined: false
            }
        ];
    }

    get canShowWaveEffects() {
        // Almost certainly equivalent to user has professional
        return this.routeList.some(routeListItem => routeListItem.params?.showWaveEffects);
    }

    get wavePolarEnabled() {
        return this.routeList.some(routeListItem => routeListItem.params?.wavePolarEnabled);
    }

    selectRouteNode(ev) {
        const $row = $j(ev.target).closest('tr'),
            {routeIndex, nodeIndex} = $row.data();
        console.log('Attempting to select', {routeIndex, nodeIndex});
        const node = this.routeList[routeIndex].complete[nodeIndex];

        let e = new CustomEvent('routing-select-route-node', {
            detail: node,
            bubbles: true,
            composed: true
        });
        this.dispatchEvent(e);
    }

    renderTable(routeListItem, routeIndex, tableInfo) {
        const dash = html`<span class="waves-no-value">-</span>`;
        return html`
          <table class="routing-waves-table">
            <thead class="clear">
            <tr>
              <th colspan="${tableInfo.headings.length}">
                <div style="display: flex; justify-content: space-between; align-items: center">
                  <span class="source-header"
                        style=${styleMap({
                          color: routeListItem.ui.textColour
                        })}>${routeListItem.ui.label}</span>
                  ${tableInfo.isNarrow ? html`
                    <pw-routing-waves-selector value="${this.waveFilter}">` : ''}
                </div>
              </th>
            </tr>
            <tr>
              ${map(tableInfo.headings, heading => html`
                <th>${heading}</th>
              `)}
            </tr>
            </thead>
            <tbody>
            ${map(routeListItem.complete || [], (node, nodeIndex) => {
              const {formatted} = getUserNode(node);
              if (!this.canShowWaveEffects && !tableInfo.isCombined) {
                const keepColumns = tableInfo.isNarrow ? 1 : 2;
                return map(tableInfo.rowFields, (fields, fieldIndex) => html`
                  <tr @click="${this.selectRouteNode}"
                      data-route-index="${routeIndex}"
                      data-node-index="${nodeIndex}">
                    ${map(fields.slice(0, keepColumns), key => html`
                      <td data-field="${key}">
                        ${key ? formatted[key] ?? dash : ''}
                      </td>`)}
                    ${nodeIndex === 0 && fieldIndex === 0 ? html`
                      <td colspan="${fields.length - keepColumns - 1}"
                          rowspan="${(routeListItem.complete || []).length * tableInfo.rowFields.length}"
                          class='wave-table-upgrade-area'>
                        <pw-routing-upgrade-overlay
                                class="routing-table-upgrade-overlay"
                                text="${txtUpgradeMessage}"></pw-routing-upgrade-overlay>
                      </td>` : ''}
                    <td data-field="WARNINGS">
                      ${formatted.WARNINGS ?? dash}
                  </tr>`);
              } else {
                return map(tableInfo.rowFields, (fields, j) => html`
                  <tr @click="${this.selectRouteNode}"
                      data-route-index="${routeIndex}"
                      data-node-index="${nodeIndex}">
                    ${map(fields, key => html`
                      <td data-field="${key}">
                        ${key ? formatted[key] ?? dash : ''}
                      </td>`)}
                  </tr>`);
              }
            })}
            </tbody>
          </table>`;
    }

    renderRoute(routeListItem, routeIndex) {
        return this.tables.map(tableInfo =>
            (!tableInfo.filter || tableInfo.filter === this.waveFilter) ?
                html`
                    <div class=${classMap({
                        [tableInfo.className]: true,
                        "wave-table-show-upgrade-area": !this.canShowWaveEffects && !tableInfo.isCombined
                    })}>
                        ${this.renderTable(routeListItem, routeIndex, tableInfo)}
                    </div>
                ` : nothing
        );
    }

    render() {
        return this.routeList.map(this.renderRoute, this);
    }

    static get styles() {
        return [
            // language=css
            css`
                :host {
                    display: block;
                }

                img {
                    -webkit-touch-callout: none; /* Don't ever show a context menu for an image. How to apply this to all shadow DOMs? */
                }

                .waves-select {
                    border-radius: 8px;
                    border: 0;
                    height: 42px;
                    font-family: inherit;
                    font-weight: 500;
                    font-size: 14px;
                    color: #000;
                    flex: 1 0 auto;

                    -webkit-appearance: none;
                    background: #F8F9F9 url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDExIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNC41ODY2IDUuOTI1NThDNS4wOTA5IDYuNDk2ODYgNS45MTExNCA2LjQ5MTk2IDYuNDExMDUgNS45MjU1OEwxMC43MjI2IDEuMDMzNTRDMTEuMjI1NyAwLjQ2MjI1MyAxMS4wMjU4IDAgMTAuMjc0MyAwSDAuNzIzMzQ4Qy0wLjAyNjUwNzMgMCAtMC4yMjM2NjkgMC40NjcxNTMgMC4yNzUwMzQgMS4wMzM1NEw0LjU4NjYgNS45MjU1OFoiIGZpbGw9ImJsYWNrIi8+Cjwvc3ZnPgo=) no-repeat right 20px center;
                    padding-left: 17px;
                    padding-right: 50px;
                }

                .wide-waves-table .waves-select {
                    display: none;
                }

                .waves-no-value {
                    opacity: 0.25;
                }

                .narrow-waves-table {
                    display: none;
                }

                .narrow-waves-table {
                    position: relative;
                    width: 100%;
                    overflow: auto;
                }

                .routing-table-upgrade-overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                }

                .narrow-waves-table .routing-table-upgrade-overlay {
                    top: 50px;
                    max-height: 300px;
                }

                .wide-waves-table .routing-table-upgrade-overlay {
                    max-height: 600px;
                }

                .narrow-waves-table .wave-table-show-upgrade-area td:first-child {
                    white-space: nowrap;
                    width: 80px;
                }

                .routing-waves-table tbody tr {
                    cursor: pointer;
                }

                .wide-waves-table .routing-waves-table tbody tr:nth-child(6n+1) td:first-child,
                .wide-waves-table .routing-waves-table tbody tr:nth-child(6n+2) td:first-child,
                .wide-waves-table .routing-waves-table tbody tr:nth-child(6n+3) td:first-child {
                    background-color: rgba(26, 47, 67, 0.07);
                }

                .wide-waves-table .routing-waves-table tbody tr:nth-child(6n+4) td:first-child,
                .wide-waves-table .routing-waves-table tbody tr:nth-child(6n+5) td:first-child,
                .wide-waves-table .routing-waves-table tbody tr:nth-child(6n+6) td:first-child {
                    background-color: rgba(26, 47, 67, 0.03);
                }

                .wide-waves-table .routing-waves-table tbody tr:nth-child(6n+1) td:not(:first-child),
                .wide-waves-table .routing-waves-table tbody tr:nth-child(6n+2) td:not(:first-child),
                .wide-waves-table .routing-waves-table tbody tr:nth-child(6n+3) td:not(:first-child) {
                    background-color: rgba(26, 47, 67, 0.11);
                }

                .wide-waves-table .routing-waves-table tbody tr:nth-child(6n+4) td:not(:first-child),
                .wide-waves-table .routing-waves-table tbody tr:nth-child(6n+5) td:not(:first-child),
                .wide-waves-table .routing-waves-table tbody tr:nth-child(6n+6) td:not(:first-child) {
                    background-color: rgba(26, 47, 67, 0.07);
                }

                .narrow-waves-table .routing-waves-table tbody tr:nth-child(odd) td:first-child {
                    background-color: rgba(26, 47, 67, 0.07);
                }

                .narrow-waves-table .routing-waves-table tbody tr:nth-child(even) td:first-child {
                    background-color: rgba(26, 47, 67, 0.03);
                }

                .narrow-waves-table .routing-waves-table tbody tr:nth-child(odd) td:not(:first-child) {
                    background-color: rgba(26, 47, 67, 0.11);
                }

                .narrow-waves-table .routing-waves-table tbody tr:nth-child(even) td:not(:first-child) {
                    background-color: rgba(26, 47, 67, 0.07);
                }

                .routing-waves-table {
                    border-collapse: collapse;
                    width: 100%;
                }

                .routing-waves-table {
                    margin-top: 40px;
                }

                .routing-waves-table .source-header {
                    font-size: 18px;
                    line-height: 18px;
                }

                .routing-waves-table td,
                .routing-waves-table th {
                    text-align: center;
                    padding: 3px 11px;
                    max-width: 145px;
                }

                .routing-waves-table td:first-child,
                .routing-waves-table th:first-child {
                    text-align: left;
                    white-space: nowrap;
                }

                .routing-waves-table tbody tr:first-child td:first-child {
                    border-radius: 8px 0px 0px 0px;
                }

                .routing-waves-table tbody tr:first-child td:last-child {
                    border-radius: 0px 8px 0px 0px;
                }

                .routing-waves-table tbody tr:last-child td:first-child {
                    border-radius: 0px 0px 0px 8px;
                }

                div:not(.wave-table-show-upgrade-area) > .routing-waves-table tbody tr:last-child td:last-child {
                    border-radius: 0px 0px 8px 0px;
                }

                .wide-waves-table .routing-waves-table tbody tr:first-child td.wave-table-upgrade-area {
                    position: relative;
                    background-color: rgba(26, 47, 67, 0.03);
                    border-radius: 0px 8px 8px 0px;
                }

                .narrow-waves-table .routing-waves-table tbody tr:first-child td.wave-table-upgrade-area {
                    position: relative;
                    background-color: transparent;
                }

                .narrow-waves-table.wave-table-show-upgrade-area td:not(.wave-table-upgrade-area) {
                    opacity: 0.12;
                }

                .wide-waves-table td[data-field=wd],
                .wide-waves-table td[data-field=mdww],
                .wide-waves-table td[data-field=mwd1],
                .wide-waves-table td[data-field=mwd2],
                .wide-waves-table td[data-field=mwd3] {
                    font-weight: 600;
                }

                /*  Intentionally allowing narrow layout on desktop.
                    Should use container media query when available or polyfill added. */
                @media only screen
                and (min-width: 320px)
                and (max-width: 736px) /* Should we change MAX_PHONE_LAYOUT_WIDTH to be 736px? GHB */
                and (orientation: portrait) {
                    .narrow-waves-table .routing-table-upgrade-overlay {
                        left: -80px;
                        height: 400px;
                    }

                    .routing-waves-table td,
                    .routing-waves-table th {
                        padding: 3px 4px;
                    }

                    .wide-waves-table {
                        display: none;
                    }

                    .narrow-waves-table {
                        display: block;
                    }
                }

                /* Inverse media query */
                @media not screen
                and (min-width: 320px)
                and (max-width: 736px)
                and (orientation: portrait) {
                    .cell-warning-container {
                        left: 14px;
                    }
                }
            `];
    }
}
