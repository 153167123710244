import {css, html, LitElement} from "lit";
import {app, isSmartPhone, isPredictWindApp, isOffshoreApp} from "../../platform.js";

const txtUpgradeToPro = gettext('Upgrade to Pro Now');

export default class PWRoutingUpgradeOverlay extends LitElement {
    static get properties() {
        return {
            text: {type: String, reflect: true}
        };
    }

    constructor() {
        super();
        this.text = 'NEEDS UPGRADE TEXT';
    }

    handleClick() {
        if (isPredictWindApp || isOffshoreApp) {
            app.client.request('upgrade', 'professional_1');
        } else if (!isSmartPhone) {
            window.location.href = 'https://forecast.predictwind.com/register/change/package';
        }
    }

    render() {
        return html`
            <div class="upgrade-overlay-container">
                <div class="upgrade-overlay-text">${this.text}</div>
                <div class="upgrade-overlay-button" @click="${this.handleClick}">
                    <span class="upgrade-overlay-button-text">${txtUpgradeToPro}</span>
                </div>
            </div>`;
    }

    static get styles() {
        // language=css
        const s = [css`
            .upgrade-overlay-container {
                position:absolute;
                top:0;
                left:0;
                bottom:0;
                right:0;
                display:flex;
                flex-flow:column nowrap;
                justify-content:center;
                align-items:center;
                gap:10px;
            }
            .upgrade-overlay-text {
                max-width:430px;
                padding:16px;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                text-align: center;
                color: #000000;
                flex:0 1 auto;
            }
            .upgrade-overlay-button {
                width:184px;
                padding: 0px;
                height:32px;
                line-height: 32px;
                text-align: center;
                background-color: #2996FC;
                border-radius: 8px;
                cursor:pointer;
                flex:0 1 auto;
            }
            .upgrade-overlay-button-text {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                color: #FFFFFF;
            }

            /*  Intentionally allowing narrow layout on desktop.
                Should use container media query when available or polyfill added. */
            @media only screen
            and (min-width: 320px)
            and (max-width: 736px)
            and (orientation: portrait) {
                .upgrade-overlay-text {
                    max-width:auto;
                    padding:16px 64px;
                }
            }
        `];
        return s;
    }
}
