import {css, html, LitElement, nothing} from "lit";
import {isSmartPhone} from "../../platform.js";
import {getUnitLabel} from "../convertable-units.js";
import {preference_units} from "../preference-units.js";

const txtUnitsTitle = gettext('Units'),
    txtWaveHeight = gettext('Wave Height'),
    txtWaveDirection = gettext('Wave Direction'),
    txtWavePeriod = gettext('Wave Period'),
    txtDirection = gettext('Direction'),
    txtRoll = gettext('Roll'),
    txtVertAccLong = gettext('Vertical Acceleration'),
    txtVertAccShort = gettext('Vert Acc'),
    txtSlamIncLong = gettext('Slamming Incidence'),
    txtSlamIncShort = gettext('Slam Inc'),
    txtPeriod = gettext('Period'),
    txtHowToReadThisTable = gettext('How to read this table');

export default class PWRoutingWavesUnitsTable extends LitElement {
    static get properties() {
        return {
            showWaveEffects: {type: Boolean}
        };
    }

    constructor() {
        super();
        this.showWaveEffects = false;
    }

    render() {
        const txtWaveHeightUnit = getUnitLabel(preference_units.wave_height);
        const txtWaveDirectionUnit = getUnitLabel(preference_units.wave_direction);
        const txtWavePeriodUnit = getUnitLabel(preference_units.wave_period);
        const txtRollUnit = getUnitLabel(preference_units.roll);
        const txtVertAccUnit = getUnitLabel(preference_units.vertical_acceleration);
        const txtSlamIncUnit = getUnitLabel(preference_units.slamming_incidence);

        const txtVertAcc = isSmartPhone ? txtVertAccShort : txtVertAccLong;
        const txtSlamInc = isSmartPhone ? txtSlamIncShort : txtSlamIncLong;
        return html`
            <div class="wave-units">
                <div class="wave-units-toplevel-container">
                    <div class="wave-units-container">
                        <div class="wave-units-heading">${txtUnitsTitle}</div>
                        <div class="wave-units-line"><div>${txtWaveHeight}</div><div>${txtWaveHeightUnit}</div></div>
                        <div class="wave-units-line"><div>${txtDirection}</div><div>${txtWaveDirectionUnit}</div></div>
                        <div class="wave-units-line"><div>${txtPeriod}</div><div>${txtWavePeriodUnit}</div></div>
                    </div>
                  ${this.showWaveEffects ?
                          html`
                            <div class="wave-units-container">
                              <div class="wave-units-heading">&nbsp;</div>
                              <div class="wave-units-line">
                                <div>${txtRoll}</div>
                                <div>${txtRollUnit}</div>
                              </div>
                              <div class="wave-units-line">
                                <div>${txtVertAcc}</div>
                                <div>${txtVertAccUnit}</div>
                              </div>
                              <div class="wave-units-line">
                                <div>${txtSlamInc}</div>
                                <div>${txtSlamIncUnit}</div>
                              </div>
                            </div>
                          ` : nothing
                  }
                  <div class="wave-units-container wave-units-triple">
                        <div class="wave-units-heading">${txtHowToReadThisTable}</div>
                        <div class="wave-units-triple-line">
                            <div>
                                <div>1.7</div>
                                <div>217</div>
                                <div>5.3</div>
                            </div>
                            <div>
                                <div>${txtWaveHeight}</div>
                                <div>${txtWaveDirection}</div>
                                <div>${txtWavePeriod}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>`;
    }

    static get styles() {
        // language=css
        const s = [css`
            .wave-units {
                width:min-content;
            }
            .wave-units-toplevel-container {
                display: flex;
                flex-flow:row nowrap;
                align-items:center;
            }
            .wave-units-container {
                width:250px;
                margin:0px 12px 0px 0px;
                flex:0 1 auto;
            }
            .wave-units-heading {
                font-weight: 700;
                font-size: 12px;
                white-space:nowrap;
                line-height:13px;
                margin:13px 0;
            }
            .wave-units-container .wave-units-line {
                line-height:30px;
                white-space:nowrap;
                background: rgba(26, 47, 67, 0.03);
                border-radius:8px;
                margin-bottom:6px;
                display: flex;
                flex-flow:row nowrap;
                align-items:baseline;
                justify-content:space-between;
            }
            .wave-units-container .wave-units-line>div {
                margin:0px 11px;
                flex:0 1 auto;
            }
            .wave-units-container .wave-units-line>div:nth-child(2) {
                font-weight: 500;
                width:80px;
            }
            .wave-units-container.wave-units-triple {
                width:190px;
                margin-left:12px;
            }
            .wave-units-container.wave-units-triple .wave-units-triple-line {
                white-space:nowrap;
                background: rgba(26, 47, 67, 0.03);
                border-radius:8px;
                margin-bottom:6px;
                display: flex;
                flex-flow:row nowrap;
                align-items:baseline;
                justify-content:space-evenly;
                width:min-content;
                line-height:26px;
            }
            .wave-units-container.wave-units-triple .wave-units-triple-line>div:first-child {
                background: rgba(26, 47, 67, 0.03);
                margin:12px 0px 12px 12px;
                border-radius:8px;
                text-align:center;
                width:auto;
            }
            .wave-units-container.wave-units-triple .wave-units-triple-line>div:first-child>div {
                margin:0px 8px;
            }
            .wave-units-container.wave-units-triple .wave-units-triple-line>div:first-child>div:nth-child(2) {
                font-weight:600;
            }
            .wave-units-container.wave-units-triple .wave-units-triple-line>div:last-child>div {
                font-weight:400;
                margin:0 13px;
            }
            .wave-units-container {
                color:#1A2F43;
            }

            /*  Intentionally allowing narrow layout on desktop.
                Should use container media query when available or polyfill added */
            @media only screen
            and (min-width: 320px)
            and (max-width: 736px)
            and (orientation: portrait) {
                .wave-units-container {
                    margin:0;
                    width:auto;
                }
                .wave-units {
                    margin:0 11px;
                    width:auto;
                }
                .wave-units-toplevel-container {
                    flex-flow:column nowrap;
                }
                .wave-units-triple {
                    display:none;
                }
                .wave-units-container:not(:first-child) .wave-units-heading {
                    display:none;
                }
                .wave-units-container {
                    width:100%;
                }
            }
        `];
        return s;
    }
}
